<template>
  <div class="data-table" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{ background: 'rgb(250, 250, 250)' }" :data="filteredTableData"
              :row-style="{ height: '57px' }" class="table-height">
      <!-- 表格列定义 -->
      <el-table-column prop="name" :label="$t('ProductManage.ConfigManage.namesOfTestItems')"></el-table-column>
      <el-table-column prop="typeName" :label="$t('ProductManage.ConfigManage.typesOfTestItems')">
        <template #header="{ column }">
          <div style="display: flex; align-items: center; justify-content: flex-start;">
            {{ column.label }}
            <div class="search-icon-container">

              <el-popover placement="bottom-end" :width="180" trigger="click">
                <template #reference>
                  <el-button link>
                    <el-icon :style="{ color: iconColor }">
                      <Filter/>
                    </el-icon>
                  </el-button>
                </template>

                <div>
                  <div style="display: flex; flex-direction: column; align-items: flex-start;">
                    <!-- 应用样式类 -->
                    <div :class="checkbox1 ? 'checked-checkbox' : ''">
                      <el-checkbox v-model="checkbox1">{{
                          $t('ProductManage.ConfigManage.systemSelfCheck')
                        }}
                      </el-checkbox>
                    </div>
                    <!-- 应用样式类 -->
                    <div :class="checkbox2 ? 'checked-checkbox' : ''">
                      <el-checkbox v-model="checkbox2">{{ $t('ProductManage.ConfigManage.dynamicTest') }}</el-checkbox>
                    </div>
                  </div>

                  <div style="text-align: right; margin-top: 5px;">
                    <el-button class="custom-reset-button" size="small" :disabled="!isAnyCheckboxChecked"
                               @click="resetFilters">{{ $t('ProductManage.ConfigManage.reset') }}
                    </el-button>
                    <el-button class="button-background-color" size="small" @click="emitFilters">
                      {{ $t('ProductManage.ConfigManage.confirm') }}
                    </el-button>
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('ProductManage.ConfigManage.helpManual')">
        <template #default="{ row }">
          <div v-if="row.documents && row.documents.length > 0">
            <el-tooltip effect="dark" placement="top-start" :content="row.documents[0].file_name">
              <el-link :underline="false" :href="row.documents[0].fileUrl" target="_blank">
                <span class="link-text-style">{{ row.documents[0].file_name }}</span>
              </el-link>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('ProductManage.ConfigManage.operation')">
        <template v-slot="scope">
          <el-button link @click="editMotor(scope.row)"><span class="link-text-style"> {{
              $t('ProductManage.ConfigManage.edit')
            }}</span></el-button>
          <el-button link v-if="isAdmin && showThreshold(scope.row)" @click="showThresholdDialog(scope.row)"><span
              class="link-text-style">{{
              $t('ProductManage.ConfigManage.thresholdValue')
            }}</span></el-button>
        </template>
      </el-table-column>

      <template v-slot:empty>
        <EmptySlot/>
      </template>

    </el-table>
  </div>

  <el-dialog ref="form" v-model="addDialogVisible" :title="$t('ProductManage.ConfigManage.edit')" width="1500"
             style="text-align: left"
  >
    <div class="steps-container">
      <el-steps :active="activeStep" finish-status="success" align-center>
        <el-step :title="$t('ProductManage.ConfigManage.testItemName')" @click="changeStep(0)"></el-step>
        <el-step :title="$t('ProductManage.ConfigManage.helpManual')" @click="changeStep(1)"></el-step>
      </el-steps>
    </div>
    <div>
      <el-form ref="form" :model="formData" label-width="150px" >

        <div v-if="activeStep === 1" style="text-align: center;margin-bottom: 40px;">
          <b>{{$t('ProductManage.ConfigManage.pleaseSelect')}}：</b><el-select v-if="activeStep === 1" v-model="formData.globalInputType"
                                           :placeholder="$t('ProductDiagnosis.pleaseSelect')">
            <el-option :label="$t('ProductManage.ConfigManage.upload')" value="upload"></el-option>
            <el-option :label="$t('ProductManage.ConfigManage.text')" value="text"></el-option>
          </el-select>
        </div>

        <template v-for="(languageCode) in ['zh', 'en', 'it', 'fr', 'de', 'nl', 'ja', 'sv', 'hu', 'pl', 'es', 'pt']">
          <el-form-item
              v-if="activeStep === 0"
              :label="`${$t('ProductManage.ConfigManage.' + languageCode)}`"
              :prop="`names.${languageCode}`"
              :key="'name-' + languageCode"
              :class="'custom-form-item'">
            <el-input v-model="formData.names[languageCode]"></el-input>
          </el-form-item>
          <el-form-item
              v-if="activeStep === 1"
              :label="`${$t('ProductManage.ConfigManage.' + languageCode)}`"
              :prop="`documents.${languageCode}`"
              :key="'document-' + languageCode"
              :class="'custom-form-item'">

            <div style="display: flex;">
              <template v-if="formData.globalInputType === 'upload'">
                <span> ({{ $t('quickDiagnosis.midDrive') }})&nbsp;&nbsp; </span>
                <el-upload
                    :file-list="formData.documents[languageCode][0]"
                    :on-remove="(file, fileList) => handleRemove(file, fileList, languageCode,0)"
                    :before-upload="(file) => handleBeforeUpload(file, languageCode,0)"
                    :limit="1"
                    accept=".pdf, .doc, .docx">
                  <el-button class="upload-button">
                    <Upload/>
                    {{ $t('ProductManage.ConfigManage.uploadDocument') }}
                  </el-button>
                  <template v-slot:file="{ file }"> <!-- 使用 v-slot 替换了 slot -->
                    <div class="file-name-wrapper">
                      <span class="file-name">{{ file.name }}</span>
                      <!-- 使用 Close 图标 -->
                      <el-button type="text" class="delete-file-button"
                                 @click="() => handleRemove(file, formData.documents[languageCode][0], languageCode,0)">
                        <el-icon>
                          <Close/>
                        </el-icon>
                      </el-button>
                    </div>
                  </template>
                </el-upload>
                <span> ({{ $t('quickDiagnosis.hubDrive') }})&nbsp;&nbsp; </span>
                <el-upload
                    :file-list="formData.documents[languageCode][1]"
                    :on-remove="(file, fileList) => handleRemove(file, fileList, languageCode,1)"
                    :before-upload="(file) => handleBeforeUpload(file, languageCode,1)"
                    :limit="1"
                    accept=".pdf, .doc, .docx">
                  <el-button class="upload-button">
                    <Upload/>
                    {{ $t('ProductManage.ConfigManage.uploadDocument') }}
                  </el-button>
                  <template v-slot:file="{ file }"> <!-- 使用 v-slot 替换了 slot -->
                    <div class="file-name-wrapper">
                      <span class="file-name">{{ file.name }}</span>
                      <!-- 使用 Close 图标 -->
                      <el-button type="text" class="delete-file-button"
                                 @click="() => handleRemove(file, formData.documents[languageCode][1], languageCode,1)">
                        <el-icon>
                          <Close/>
                        </el-icon>
                      </el-button>
                    </div>
                  </template>
                </el-upload>
              </template>

              <template v-else>
                <div style="display: flex;justify-content: space-between;gap: 10px;">
                  <span> ({{ $t('quickDiagnosis.midDrive') }})&nbsp;&nbsp; </span>
                  <el-input
                      type="textarea"
                      style="width: 500px"
                      v-model="(formData.documents[languageCode][0][0] || { content: '' }).content"
                      :placeholder="$t('ProductManage.ConfigManage.midDrivePlaceholder')"
                  ></el-input>
                  <span> ({{ $t('quickDiagnosis.hubDrive') }})&nbsp;&nbsp; </span>

                  <el-input
                      type="textarea"
                      style="width: 500px"
                      v-model="(formData.documents[languageCode][1][0] || { content: '' }).content"
                      :placeholder="$t('ProductManage.ConfigManage.hubDrivePlaceholder')"
                  ></el-input>
                </div>
              </template>

            </div>

          </el-form-item>
        </template>
        <hr class="dialog-separator"/>

      </el-form>
    </div>

    <span class="dialog-footer">
      <el-button @click="prevStep" :disabled="activeStep === 0" v-if="activeStep === 1"
                 class="custom-reset-button">{{ $t('ProductManage.ConfigManage.previousStep') }}</el-button>
      <el-button @click="nextStep" v-if="activeStep === 0"
                 class="custom-reset-button">{{ $t('ProductManage.ConfigManage.nextStep') }}</el-button>
      <el-button class="button-background-color" @click="saveData"
                 v-if="activeStep === 1">{{ $t('ProductManage.ConfigManage.confirm') }}</el-button>
    </span>
  </el-dialog>

  <el-dialog style="text-align: left;" width="400" :title="$t('ProductManage.ConfigManage.thresholdValue')"
             v-model="thresholdDialogVisible">
    <el-form :model="thresholdList" label-width="80px" ref="thresholdForm">

      <!-- 通过v-for循环渲染阈值输入框和适配组织下拉框 -->
      <div v-for="(item, index) in thresholdList" :key="index" class="threshold-item">
        <!-- 阈值输入框 -->
        <div class="threshold-input">
          {{ $t('ProductManage.ConfigManage.thresholdValue') }}
          <el-input-number v-model="item.thresholdValueLeft" :controls="false" style="width: 100px;"></el-input-number>
          <span style="margin: 0 10px;">~</span>
          <el-input-number v-model="item.thresholdValueRight" :controls="false" style="width: 100px;"></el-input-number>
        </div>
        <!-- 下拉框 -->
        <el-form-item :label="$t('ProductManage.ConfigManage.compatibilityOrganization')" prop="compatibleOrganization"
                      class="threshold-select">
          <el-tree-select v-model="item.compatibleOrganization" :data="organizationOptions"
                          :props="{ label: 'label', children: 'children' }" show-checkbox default-expand-all multiple
                          filterable
                          :placeholder="$t('ProductManage.ConfigManage.compatibilityOrganizationPlaceholder')"
                          :render-after-expand="false" clearable style="width: 90%;" check-strictly
                          check-on-click-node/>
        </el-form-item>
        <!-- 删除图标 -->
        <el-icon @click="handleDeleteThreshold(index)" class="delete-icon">
          <Delete/>
        </el-icon>
      </div>

    </el-form>

    <!-- 增加阈值按钮 -->
    <!-- 按钮容器 -->
    <el-button @click="handleAddThreshold" type="primary" class="custom-button ">
      <el-icon>
        <Plus/>
      </el-icon>
      <span class="button-text">{{ $t('ProductManage.ConfigManage.addThreshold') }}</span>
    </el-button>

    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="thresholdDialogVisible = false" class="custom-reset-button">
        {{ $t('ProductManage.ConfigManage.cancel') }}
      </el-button>
      <el-button class="button-background-color" @click="addThresholdData">
        {{ $t('ProductManage.ConfigManage.confirm') }}
      </el-button>
    </div>
  </el-dialog>


</template>

<script>
import resizeMixin from "@/mixins/resizeMixin";
import {Close, Delete, Filter, Plus, Upload} from "@element-plus/icons-vue";
import {
  getOrganizationsData,
  getTestItemById,
  getTestItemThresholds,
  listToTree,
  saveTestItemDocuments,
  saveThresholdData,
  uploadFileData
} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot, Close, Filter, Plus, Delete, Upload},
  mixins: [resizeMixin, dialogMixin],

  data() {
    return {
      isAdmin: false,
      addDialogVisible: false,
      thresholdDialogVisible: false,
      motorForm: {
        TestItemName: '',
        TestItemType: '',
        videoFileList: [],
      },
      motorFormRules: {
        TestItemName: [
          {required: true, message: this.$t('ProductManage.ConfigManage.testItemNameRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.ConfigManage.testItemNamePattern'),
            trigger: 'blur',
          },
        ],
      },
      activeStep: 0,
      stepsCompleted: [false, false, false, false], // 初始状态，只有第一步是可点击的

      formData: {
        globalInputType: 'upload', // 全局 inputType，控制所有语言
        names: {
          zh: "", en: "", it: "", fr: "", de: "", nl: "", ja: "", sv: "", hu: "", pl: "", es: "", pt: "" // 添加es和pt语言
        },
        documents: {
          zh: [[], []], // 第一个数组存储中置文件，第二个数组存储轮毂文件
          en: [[], []],
          it: [[], []],
          fr: [[], []],
          de: [[], []],
          nl: [[], []],
          ja: [[], []],
          sv: [[], []],
          hu: [[], []],
          pl: [[], []],
          es: [[], []],
          pt: [[], []], // 添加es和pt语言的文档字段
        }
      },
      // 适配组织下拉框的值
      modifyThresholdData: {
        compatibleOrganization: '',
        thresholdValueLeft: '',
        thresholdValueRight: '',
        id: 1, // 初始一对的标识符
      },
      thresholdList: [], // 确保 thresholdList 是一个数组
      checkbox1: false,
      checkbox2: false,
      currentTestItemId: null,

      item: {
        thresholdValueLeft: '',
        thresholdValueRight: '',
        compatibleOrganization: []  // 确保这个属性存在，以避免绑定错误
      },
      organizationOptions: [],

      filteredTableData: [],
      localTableData: [], // 添加一个本地变量来替代传递的 props
      languageMap: {
        zh: this.$t('ProductManage.ConfigManage.zh'),
        en: this.$t('ProductManage.ConfigManage.en'),
        it: this.$t('ProductManage.ConfigManage.it'),
        fr: this.$t('ProductManage.ConfigManage.fr'),
        de: this.$t('ProductManage.ConfigManage.de'),
        nl: this.$t('ProductManage.ConfigManage.nl'),
        ja: this.$t('ProductManage.ConfigManage.ja'),
        sv: this.$t('ProductManage.ConfigManage.sv'),
        hu: this.$t('ProductManage.ConfigManage.hu'),
        pl: this.$t('ProductManage.ConfigManage.pl'),
        es: this.$t('ProductManage.ConfigManage.es'),
        pt: this.$t('ProductManage.ConfigManage.pt'),
      },
      currentLanguage: this.$i18n.locale, // 当前语言
    };
  },
  props: {
    tableData: Array, // 电机数据
    loading: Boolean,  // 接收 loading 状态
    getList: {
      type: Function,
      required: true,
    },
  },
  watch: {
    tableData: {
      immediate: true,
      handler(newVal) {
        this.localTableData = newVal;
        this.updateLocalizedTableData();
      }
    },
    '$i18n.locale': {
      immediate: true,
      handler(newLang) {
        this.currentLanguage = newLang;
        this.updateLocalizedTableData();
      }
    },
  },
  mounted() {
    this.fetchOrganizationOptions();
    this.checkUserRole();
  },
  computed: {
    iconColor() {
      return this.checkbox1 || this.checkbox2 ? 'green' : 'grey';
    },
    isAnyCheckboxChecked() {
      return this.checkbox1 || this.checkbox2;
    },
  },
  methods: {
    updateLocalizedTableData() {
      this.filteredTableData = this.localTableData.map(item => {
        const documents = item.documents || [];
        const currentLanguageDocument = documents.find(doc => doc.lang === this.currentLanguage);
        const defaultLanguageDocument = documents.find(doc => doc.lang === 'en');
        const typeName = item.typeName === 0 ? this.$t('ProductManage.ConfigManage.dynamicTest') : this.$t('ProductManage.ConfigManage.systemSelfCheck');
        const type = item.typeName;
        return {
          ...item,
          name: currentLanguageDocument ? currentLanguageDocument.name : (defaultLanguageDocument ? defaultLanguageDocument.name : this.$t('ProductManage.unknownName')),
          documents: currentLanguageDocument ? [currentLanguageDocument] : (defaultLanguageDocument ? [defaultLanguageDocument] : []),
          typeName: typeName, // 更新 typeName 根据当前语言
          type: type
        };
      });
    },
    checkUserRole() {
      const userRoles = JSON.parse(localStorage.getItem('user_role')) || [];
      this.isAdmin = userRoles.includes("Super Admin") || userRoles.includes("管理员") || userRoles.includes("Admin");
    },
    async addThresholdData() {
      let allFieldsValid = true;

      // 遍历所有阈值和组织，确保它们都被填写或选择
      for (const item of this.thresholdList) {
        // 检查阈值是否为 null 或 undefined
        if (item.thresholdValueLeft == null || item.thresholdValueRight == null) {
          allFieldsValid = false;
          this.$message.error(this.$t('ProductManage.ConfigManage.allFieldsRequired'));
          break;
        }
        if (!item.compatibleOrganization.length) {
          allFieldsValid = false;
          this.$message.error(this.$t('ProductManage.ConfigManage.selectAtLeastOneOrganization'));
          break;
        }
      }

      if (!allFieldsValid) {
        return;  // 如果有任何字段未通过验证，停止执行
      }
      // 组装数据，处理有无id的情况
      const data = {
        test_item_id: this.currentTestItemId,  // 获取当前测试项的ID
        thresholdList: this.thresholdList.map(item => {
          const thresholdData = {
            begin: item.thresholdValueLeft,
            end: item.thresholdValueRight,
            organization_ids: item.compatibleOrganization.join(',')
          };
          if (item.id) {
            thresholdData.id = item.id; // 仅在编辑时添加id
          } else {
            thresholdData.id = null;
          }
          return thresholdData;
        })
      };

      try {
        // 调用 API 函数保存数据
        await saveThresholdData(data);
        this.thresholdDialogVisible = false; // 关闭对话框
        this.$message.success(this.$t('ProductManage.ConfigManage.thresholdSaveSuccess'));
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.thresholdSaveFailed'));
      }
    },

    async fetchOrganizationOptions() {
      try {
        const response = await getOrganizationsData();
        const treeData = listToTree(response.data);  // 确保listToTree逻辑正确
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.fetchOrganizationFailed'));
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children || [])
      }));
    },
    async handleBeforeUpload(file, languageCode, type) {
      // 假设文件大小限制为 5MB
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        this.$message.error(this.$t('ProductManage.ConfigManage.fileTooLarge'));
        return false;
      }
      const formData = new FormData();
      formData.append('file', file);

      const response = await uploadFileData(formData); // 调用上传文档接口
      if (response.code === 0) {
        this.$message.success(this.$t('ProductManage.uploadSuccess'));
        // 生成文件的 UID，这里可以是一个时间戳加上文件名，或者使用其他方法生成唯一标识符
        const fileUid = Date.now() + '-' + file.name;
        // 确保文件对象包括 UID，用于后续操作识别
        const fileObject = {
          type: type,
          name: response.fileName,  // 文件名
          url: response.url,   // 文件访问URL
          key: response.key, // 文件名
          file: response.key, // 文件名
          uid: fileUid         // 唯一标识符
        };
        // if (type === 1) {
        // 将新上传的文件添加到对应语言的文件列表中
        this.formData.documents[languageCode][type] = this.formData.documents[languageCode][type] || [];
        this.formData.documents[languageCode][type].push(fileObject);
      } else {
        this.$message.error(this.$t('ProductManage.uploadFailed'));
      }
      // 阻止 el-upload 组件自动上传
      return false;
    },

    handleRemove(file, fileList, languageCode, type) {
      // 使用 UID 来定位和移除文件
      this.formData.documents[languageCode][type] = this.formData.documents[languageCode][type].filter(f => f.uid !== file.uid);
    },

    async editMotor(motor) {
      this.currentTestItemId = motor.id; // 保存当前要编辑的测试项的ID

      try {
        // 从API获取测试项的详细信息
        const response = await getTestItemById(motor.id);
        const motorDetails = response.data; // 假设响应结构是 { data: motorDetails }
        // 先重置 formData
        this.resetFormData();
        this.formData.globalInputType = motorDetails.document_type === 1 ? 'text' : motorDetails.document_type === 0 ? 'upload' : '';
        // 使用 documents 数组填充 formData
        motorDetails.documents.forEach((document) => {
          // 使用 Object.prototype.hasOwnProperty 调用来替代直接调用
          if (document.lang && Object.prototype.hasOwnProperty.call(this.formData.names, document.lang)) {
            // 设置名称
            this.formData.names[document.lang] = document.name;
            // 设置文档
            this.formData.documents[document.lang][document.type] = [{
              type: document.type,
              key: document.file,
              uid: document.id, // 假设您可以使用文档的id作为uid
              url: document.fileUrl,
              name: document.file_name, // 添加 key
              content: document.content,
            }];
          }

        });
        this.addDialogVisible = true; // 显示编辑对话框
        this.activeStep = 0;

      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('ProductManage.ConfigManage.editFailed'));
      }
    },

    resetFormData() {
      // 初始化或重置 formData 结构
      this.formData = {
        names: {},
        documents: {},
        inputType: {
          zh: '', en: '', it: '', fr: '', de: '', nl: '', ja: '', sv: '', hu: '', pl: '', es: '', pt: '' // 初始化es和pt
        },
      };

      const languageCodes = ['zh', 'en', 'it', 'fr', 'de', 'nl', 'ja', 'sv', 'hu', 'pl', 'es', 'pt'];
      languageCodes.forEach(code => {
        this.formData.names[code] = "";
        this.formData.documents[code] = [[], []]; // 正确初始化为二维数组
      });
    },

    async showThresholdDialog(row) {
      this.currentTestItemId = row.id;  // 保存当前行的ID为测试项ID
      try {
        // 调用新的接口获取阈值数据
        const thresholds = await getTestItemThresholds(this.currentTestItemId);
        this.thresholdList = thresholds.data.map(item => ({
          id: item.id,
          thresholdValueLeft: item.begin,
          thresholdValueRight: item.end,
          compatibleOrganization: item.organization.map(org => org.id), // 将组织的ID数组转换成数组
        }));
        // 显示阈值对话框
        this.thresholdDialogVisible = true;
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.fetchThresholdFailed'));
      }
    },

    resetForm() {
      this.$refs.motorForm.resetFields();
    },

    confirmDelete() {
      // 在成功删除后关闭确认删除弹框
      this.thresholdDialogVisible = false;
    },
    handleDeleteClose(done) {
      // 关闭确认删除弹框时重置状态
      this.motorToDelete = null;
      done();
    },
    addMotorData() {
      this.addDialogVisible = false;
    },
    nextStep() {
      if (this.activeStep === 0) {
        // 手动触发表单验证
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.activeStep = 1;
          }
        });
      } else if (this.activeStep === 1) {
        this.activeStep = 2;
      }
    },
    changeStep(targetStep) {
      // 检查所有先前的步骤是否已完成
      for (let i = 0; i < targetStep - 1; i++) {
        if (!this.stepsCompleted[i]) {
          return; // 如果有任何一个步骤未完成，不允许更改步骤
        }
      }
      this.step = targetStep; // 更改步骤
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },
    async saveData() {
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          const dataToSave = {
            test_item_id: this.currentTestItemId, // 使用保存的当前测试项ID
            documents: [],
            document_type: this.formData.globalInputType === 'text' ? 1 : this.formData.globalInputType === 'upload' ? 0 : 0,
          };

          // 为每种语言构建文档对象并加入到documents数组中
          for (const lang of Object.keys(this.formData.documents)) {
            if (this.formData.documents[lang][0] && this.formData.documents[lang][0].length > 0) {
              dataToSave.documents.push({
                name: this.formData.names[lang],
                file_name: this.formData.documents[lang][0][0].name,
                file: this.formData.documents[lang][0][0].key, // 此处假设文件对象包含文件名
                lang: lang,
                type: 0, // 中置文件
                content: this.formData.documents[lang][0][0].content,
              });
            }

            // 处理轮毂文件
            if (this.formData.documents[lang][1] && this.formData.documents[lang][1].length > 0) {
              dataToSave.documents.push({
                name: this.formData.names[lang],
                file_name: this.formData.documents[lang][1][0].name,
                file: this.formData.documents[lang][1][0].key, // 文件键
                lang: lang,
                type: 1, // 轮毂文件
                content: this.formData.documents[lang][1][0].content,
              });
            }

          }
          // 调用保存数据的函数，发送整个 dataToSave 对象
          try {
            const response = await saveTestItemDocuments(dataToSave);
            if (response.code === 0) {
              this.$message.success(this.$t('ProductManage.ConfigManage.dataSaveSuccess'));
            } else {
              this.$message.error(this.$t('ProductManage.ConfigManage.dataSaveError') + response.message);
            }
            this.addDialogVisible = false;
            this.resetFormData();
            await this.getList();
          } catch (error) {
            console.error(this.$t('ProductManage.ConfigManage.dataSaveException'));
          }
        }
      });

    },

    handleAddThreshold() {
      // 创建一个新的阈值对象，可以根据需要进行初始化
      const newThreshold = {
        id: null,
        thresholdValueLeft: 0,
        thresholdValueRight: 0,
        compatibleOrganization: [],
      };

      // 将新的阈值对象添加到 thresholdList 数组
      this.thresholdList.push(newThreshold);
    },
    handleDeleteThreshold(index) {
      this.thresholdList.splice(index, 1);
    },
    resetFilters() {
      this.checkbox1 = false;
      this.checkbox2 = false;
    },
    emitFilters() {
      const selectedTypes = [];
      if (this.checkbox1) selectedTypes.push(1); // 系统自检
      if (this.checkbox2) selectedTypes.push(0); // 动态测试
      // 将数组转换为逗号分隔的字符串
      const selectedTypesStr = selectedTypes.join(',');

      // 构建filters对象
      const filters = {};
      if (selectedTypesStr) {
        filters['type-in'] = selectedTypesStr;
      }

      // 仅在filters对象非空时触发事件
      this.$emit('apply-filters', filters);

    },

    // 新增方法判断是否显示阈值按钮
    showThreshold(row) {
      // 获取不需要显示阈值的类型，并将其转换为小写
      const typesWithoutThreshold = [
        this.$t('ProductManage.ConfigManage.keyDetection').toLowerCase(),
        this.$t('ProductManage.ConfigManage.headlightDetection').toLowerCase(),
        this.$t('ProductManage.ConfigManage.gearSensorDetection').toLowerCase(),
        this.$t('ProductManage.ConfigManage.brakeSensorDetection').toLowerCase(),
        this.$t('ProductManage.ConfigManage.motorDriveDetection').toLowerCase()
      ];

      if (row.type === 1) {
        return false; // 系统自检不显示阈值
      }

      // 将 row.name 转换为小写后进行比较
      return !(row.type === 0 && typesWithoutThreshold.includes(row.name.toLowerCase()));
    },

  },
};
</script>

<style scoped>
:deep(.el-upload-list) {
  width: 500px;
}

:deep(.el-upload-list__item-name) {
  width: 300px;
}

.word-family {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 设置字体系列 */
}

.search-icon-container {
  margin-left: 10px; /* 调整这个值来控制图标与文字的间距 */
}

.custom-form-item {
  margin-bottom: 20px; /* 添加下边距，创建间隔 */
}

.file-name-wrapper {
  display: flex;
  justify-content: space-between; /* 确保内容分布在两端 */
  align-items: center; /* 垂直居中 */
  width: 100%; /* 确保使用全部宽度 */
}

.file-name {
  flex-grow: 1; /* 让文件名占据大部分空间 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* 确保文件名在一行显示 */
}

.delete-file-button {
  color: inherit; /* 删除按钮使用继承的字体颜色，通常是无色 */
  padding: 0; /* 移除按钮内边距 */
  margin-left: 10px; /* 在文件名和删除按钮之间添加一些空间 */
  cursor: pointer;
}

.checked-checkbox {
  background-color: rgb(195, 217, 201);
  border-radius: 0;
  width: 100% !important;
  height: auto !important;
}

.initial-threshold-container .delete-icon {
  cursor: pointer;
}

.custom-button {
  display: inline-flex;
  /* 使用内联 flex 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  height: 100%;
  /* 确保按钮高度填满 */
  /* ... 其他样式 ... */
}

/* Element UI 图标特定样式 */
.custom-button .el-icon {
  margin-right: 8px;
  /* 图标和文本之间的间距 */
}

/* 确保文本也居中对齐 */
.custom-button .button-text {
  line-height: 1;
  /* 重置行高，防止影响居中对齐 */
}


/* 可选，如果您想要更细粒度的控制文本样式 */
.custom-button .button-text {
}

/* 自定义按钮样式 */
.custom-button {
  width: 100%;
  /* 新增：让按钮宽度填满父容器 */
  border: 1px dashed rgb(217, 217, 217);
  /* 按钮的边框颜色，可根据实际情况调整 */
  color: #000000D9;
  /* 设置字体颜色为黑色，不透明度约85% */
  background-color: #fff;
  /* 按钮背景颜色，可根据实际情况调整 */
  padding: 12px 20px;
  /* 按钮的内边距，可根据实际情况调整 */
  font-size: 14px;
  /* 字体大小，可根据实际情况调整 */
  cursor: pointer;
  /* 鼠标悬停时的指针样式 */
  text-align: center;
  /* 文本居中 */
  border-radius: 4px;
  /* 边框圆角 */
  box-sizing: border-box;
  /* 盒模型，确保边框和内边距不会增加按钮的宽度 */
  display: block;
  /* 或者使用 'inline-block' 如果您不想按钮独占一行 */
  margin-bottom: 10px;
  /* 如果需要在按钮下方添加一些间隔 */
}

.custom-button:hover {
  color: rgb(0, 154, 68);
  /* 悬停时文字颜色为绿色 */
  border-color: rgb(0, 154, 68);
  /* 悬停时边框颜色为绿色 */
  background-color: white;
  /* 悬停时背景色为白色 */
}

.threshold-select {
  min-width: 295px; /* 设置最小宽度以确保下拉框足够宽 */
}

.threshold-input,
.threshold-select {
  flex: 1; /* 使输入和选择框扩展填满除删除图标外的空间 */
  margin-right: 10px; /* 右边距，保持间隔美观 */
}

.threshold-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 确保子元素在交叉轴的起点对齐 */
  margin-bottom: 20px;
  position: relative;
  /* 为删除图标定位提供参考 */
}

.threshold-item .delete-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  /* 垂直居中 */
  cursor: pointer;
  margin-left: 20px;
}

.threshold-input {
  width: 100%;
  /* 确保输入框和下拉框宽度一致 */
  margin-bottom: 10px;
  /* 为了美观，在元素之间添加一些间距 */
  margin-left: 15px;
}

.threshold-item {
  background-color: rgb(240, 240, 240);
  /* 灰色背景色，可以根据需要修改颜色 */
  padding: 10px;
  /* 可选：添加一些内边距，使背景色不会直接贴边 */
  margin-bottom: 10px;
  /* 可选：添加一些底部外边距，使每对显示框之间有间距 */
}

.data-table {
  margin: 20px 20px 10px 20px;
  /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 5px 10px;
    /* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 15px 30px;
    /* 在中等屏幕下增加间隔 */
  }

}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
